/* global Office */

Office.onReady(() => {
    async function recalculateCommand(event: Office.AddinCommands.Event) {
        try {
            await Excel.run(async (context) => {
                context.application.calculate(Excel.CalculationType.full);    
            });
        } catch (error) {
            //TODO: Maybe show a notification to the user by using Office.addin.showAsTaskpane(); and some parameter stuff
            console.error(error);
        }
        
        event.completed();
    }

    // Register the function with Office.
    Office.actions.associate("recalculateCommand", recalculateCommand);
});
